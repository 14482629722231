import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Layout from '../components/layout';
import Seo from "../components/seo";
import { isSSR } from '../utils/utils';
import HeaderPage from '../utils/HeaderPage';
import SimpleContentPage from '../utils/SimpleContentPage'
import "../styles/style-about.css";

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDozMjMx") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const About = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  //
  useEffect(() => {
    if (isSSR()) {
        return;
    }
    //
    const items = document.querySelectorAll('.read-more-button');
    items.forEach((item) => item.addEventListener('click', function() {
      const content = this.parentElement.querySelector('.read-more-content');
      if (content.style.maxHeight === '' || content.style.maxHeight === '0px') {
          content.style.maxHeight = content.scrollHeight + "px";
          this.innerHTML = 'Read Less';
      } else {
          content.style.maxHeight = '0';
          this.innerHTML = 'Read More';
      }
    }));
  }, []);
  return (
    <Layout>
      <Seo title="About"  description={common.seo.metaDesc} canonical={common.uri} seo={common.seo} />
      <div class="main-bg">
        <div class="overlay"></div>
        <img src="/assets/images/about1.jpg" alt=""/>
        <h1>About Us</h1>
      </div>

      <div class="paramaindiv">
        <div class="sidepara">
            <p>Our team is a rare blend of people with legal and technical expertise from top-tier law firms. The IP
                work by our attorneys has laid the foundations for public companies and emerging unicorns. With
                offices in Silicon Valley, Vietnam, and Kenya, we aim to serve the global IP needs of our clients,
                large companies as well as start-ups.</p>
            <p>As advisors to disruptive companies, especially in the technology and small, medium, high-growth
                sectors, we know first-hand the power that innovation has. We invest significant time and resources
                into developing and implementing the systems and infrastructure that will dramatically improve the
                client experience.</p>
            <p>PatentPC considers you a partner and not a transaction. Our goals are your goals. These are the
                characteristics that make us unique and distinguish us as the go-to IP law firm to safeguard your
                innovations</p>
        </div>
        <div class="paraimg" style={{ marginBottom: 50 + 'px'}}>
            {/* <img src="/assets/images/laptop-2589420_1280-3-1024x681-1.jpg" alt=""/> */}
            <video style={{ minHeight: 275 + 'px', width: 500 + 'px' }} poster="/assets/images/about-thumb.png"  preload="metadata" controls>
                <source src="https://wp.patentpc.com/wp-content/uploads/2023/12/patentpc-explainer.mp4" type="video/mp4"></source>
            </video>
        </div>
      </div>

      <div class="cardmaincontainer">
        <div class="cardheading">Silicon Valley Office</div>
        <div class="csubcontainer">
            <img class="cardimg" src="/assets/images/bao-2-1.webp" alt=""/>
            <h3>Bao Tran</h3>
            <p>
                Patent Attorney Bao Tran has over twenty-five years of experience in the intellectual property field and
                has extensive experience in counseling and management of intellectual property portfolios, patent and
            </p>
            <div class="read-more-content">
                <p>
                    trademark preparation and prosecution. Since 1993, he has patented a wide variety of technologies
                    including AI, blockchain, 5G, medical devices, microbial tech, legal tech, semiconductors,
                    automotive, business methods, computer hardware, electronics, internet applications, materials,
                    electric vehicles, nanotechnology, and software. He invented the on-line workflow for patent filing
                    and prosecution which we use at PatentPC to help our clients obtain high-quality patents. He has
                    perspectives as both in-house and outside counsel to a variety of companies ranging from start-ups
                    to public companies in intellectual property licensing and monetization, and enforcement. <br/> <br/>
                    Prior to founding the firm, Mr. Tran was a Partner at Tran & Associates, General Counsel at The
                    Resource Group (TRG), Associate General Counsel at Align Technology (NASDAQ: ALGN), and Fish &
                    Richardson. He has a BSEE from Rice University, an MBA from Columbia University, and a JD from the
                    University of Houston Law Center.
                </p>
            </div>
            <p class="read-more-button">Read More</p>
        </div>

        <div class="csubcontainer">
            <img class="cardimg" src="/assets/images/image.webp" alt=""/>
            <h3>Penelope Chapman</h3>
            <p>
                Penelope is a patent agent at PatentPC. Her experiences include patent preparation and prosecution,
                litigation support, and drafting opinions related to the electrical and software area.
            </p>
            <div class="read-more-content">
                <p>
                    She has a deep technical background in computer and electrical engineering. Her research was focused
                    on hardware-based numerical algorithms. She was at Monolithic3D where I gained top-level
                    semiconductor experience and patent drafting for the technologies developed there and was
                    responsible for the assessments of 3D ICs including speed degradation analysis. She worked on
                    patents in fields ranging from data science and machine intelligence to the design and manufacture
                    of semiconductor devices and other related technologies. she has worked in all areas of United
                    States and International patent prosecution. This includes patent application preparation, response
                    to office actions, examiner interviews, portfolio strategy, and responses to office actions.
                </p>
            </div>
            <p class="read-more-button">Read More</p>
        </div>
        <div class="csubcontainer">
            <img class="cardimg" src="/assets/images/image_2022_01_14T18_55_12_510Z-1.png" alt=""/>
            <h3>Grace Manning</h3>
            <p>
                Grace is currently a patent agent at PatentPC. Her experiences include patent preparation and
                prosecution, litigation support, and drafting opinions related to the electrical and mechanical arts.
            </p>
            <div class="read-more-content">
                <p>
                    She is experienced in managing and leading engineering activities developing advanced technology
                    solutions and has worked on patents with complex electro-mechanical systems including
                    hybrid/electric vehicle control systems, PEM fuel cells, and advanced energy storage systems. Her
                    experience in patent litigation provides valuable insight into the patent prosecution process. Grace
                    notes “I interact with examiners in such a way that preserves the full scope and maximizes client
                    resources, I avoid common prosecution pitfalls such as leaving a record that could prove problematic
                    in future litigation.” <br/> <br/> Her undergraduate degree in electrical engineering technology is
                    from Elon University. Her volunteer activities include providing enlightening cultural, educational,
                    and social opportunities that transform the way individuals perceive their abilities, possibilities,
                    and environment.
                </p>
            </div>
            <p class="read-more-button">Read More</p>
        </div>

        <div class="csubcontainer">
            <img class="cardimg" src="/assets/images/v3_0113981-qbwus1c7m467k3bdagwrpfzfcnxpjaxvlf03hylj2o.jpg"
                alt=""/>
            <h3>Dr. Kurt Brown, J.D., Ph.D.</h3>
            <p>
                Dr. Brown is of-counsel and works with biopharmaceutical clients worldwide in all areas of patent
                procurement, including domestic and foreign patent preparation and lifecycle management strategies.
            </p>
            <div class="read-more-content">
                <p>
                    With his extensive expertise, he assists innovator IP portfolios from a generic perspective,
                    including method-of-use claim coverage relative to the product label, Orange Book patent listing
                    strategies, Patent Term Extension, and data exclusivity. <br/> <br/>Dr. Brown also is experienced in
                    conducting searches to determine the availability of marks, filing, preparing, and prosecuting
                    trademark applications, rendering trademark opinions, and counseling clients on a wide range of
                    trademark issues. Dr. Brown counsels the firm’s clients to develop and protect their portfolios. Mr.
                    Tran utilizes his extensive network of foreign associates to protect the intellectual property of
                    the firm’s clients worldwide. Dr. Brown has a JD from the University of Houston and is a registered
                    patent attorney.
                </p>
            </div>
            <p class="read-more-button">Read More</p>
        </div>
      </div>

      <div class="b-cardmaincontainer">
          <div class="cardheading">Asia Office</div>
          <div class="csubcontainer">
              <img class="cardimg" src="/assets/images/Phuong-Tran-Tran-1-192x300-1.jpg" alt=""/>
              <h3>Peter Phuong Tran</h3>
              <p>
                  Attorney Tran has over 20 years of experience in intellectual property law. Peter has extensive
                  expertise in dispute resolution and the management of patent claims. He has extensive experience in
                  matters related to patent registration with the Hanoi National Office of Intellectual Property and
                  resolving intellectual
              </p>
              <div class="read-more-content">
                  <p>
                      property rights disputes under the Vietnamese legal system. <br/> <br/> Peter is a member of Vietnam
                      Intellectual Property Association (VIPA), International Trademark Association (INTA), Asian Patent
                      Attorneys Association (APAA), International Intellectual Property Rights Association International
                      Association of Intellectual Property (AIPPI), International Federation of Intellectual Property
                      Lawyers (FICPI), ASEAN Intellectual Property Association (ASEAN IPA), International Association of
                      Entrepreneurs (IABL). <br/> <br/> Mr. Tran graduated with a bachelor’s degree in chemistry from Hanoi
                      National University in 1996. In 1998, he received a Master’s degree in Materials Science from the
                      International Institute of Materials Science, a program sponsored by the Dutch government. He was
                      granted a Vietnamese patent law practice certificate according to the standards of the National
                      Office of Intellectual Property of Vietnam (NOIP) in 2003. While he is based in Vietnam, Mr. Tran is
                      highly experienced in industrial property law for patent professionals in many Asian countries, such
                      as Korea, Singapore, and Japan.
                  </p>
              </div>
              <p class="read-more-button">Read More</p>
          </div>

          <div class="csubcontainer">
              <img class="cardimg" src="/assets/images/Anh_Tran-Duc-Quyen.jpg" alt=""/>
              <h3>Duke Quyen</h3>
              <p>
                  Mr. Quyen specializes in chemical and material inventions. He has significant experience in all areas of
                  intellectual property practice including patent search; managing the patent portfolio and participating
                  in technology valuation, negotiating and drafting patent transfer contracts,
              </p>
              <div class="read-more-content">
                  <p>
                      and technology transfer contracts. He also prepares validity and non-infringement opinions, conducts
                      freedom-to-operate and competitive landscape analyses, and provides litigation support. Drawing on
                      his background in chemistry and organic chemistry and research experience in small molecule
                      medicinal chemistry, he works primarily with clients in the life sciences, biotechnology, and
                      pharmaceuticals sectors. <br/> <br/>

                      Mr. Quyen graduated with a master’s degree in chemistry from Vietnam Hanoi National University where
                      his research focused on the design, synthesis, and SAR investigation of nucleoside analogs and
                      prodrugs to develop anticancer and antiviral therapeutics.
                  </p>
              </div>
              <p class="read-more-button">Read More</p>
          </div>
          <div class="csubcontainer">
              <img class="cardimg" src="/assets/images/Anh_Nguyen-Truong-Son_3.jpg" alt=""/>
              <h3>Son Nguyen, Ph.D.</h3>
              <p>
                  Dr. Nguyen is an expert in chemistry, biotechnology, and materials science. Dr. Nguyen has 20 years of
                  experience as a senior scientist at Mircobiotix, Inc., (USA); Postdoctoral fellow at the University of
                  Illinois Urbana-Champaign (USA), a graduate student at the University of Minnesota, Twin Cities (USA).
              </p>
              <div class="read-more-content">
                  <p>
                      Dr. Son has many notable research papers and presentations. He was awarded the Thermo Scientific
                      Certificate for LC/MS Operations, the ACS Minnesota Travel Grant Award in the US, and received many
                      other awards. He has significant experience in preparing and prosecuting patents with a focus on
                      chemical, biological, drug, and food chemistry-related technology as well as preparing freedom to
                      operate, patentability, and invalidity opinions. Dr. Son is excellent at patent prosecution
                      arguments, learning cutting-edge technologies, detail-oriented, presenting scientific information to
                      a variety of audiences, communicating precisely, thinking critically, developing and implementing
                      business strategies, and building collaborative relationships with clients, investigators,
                      physicians, engineers, business and research organizations. Scientific expertise includes
                      biochemistry, therapeutic molecules (such as immunological molecules, peptides, and small
                      molecules), pharmacology, physiology, biotechnology, life sciences, diagnostic devices, wearables,
                      oncology, cardiology, renal biology, hypertension, diabetes, and pulmonology.
                  </p>
              </div>
              <p class="read-more-button">Read More</p>
          </div>


      </div>

      <div class="c-cardmaincontainer">
        <div class="cardheading">Africa Office</div>
        <div class="csubcontainer">
            <img class="cardimg" src="/assets/images/maureen-headshot-300x297-1.png" alt=""/>

            <h3>Maureen Gitau</h3>
            <p>
                Ms. Gitau is a patent agent who specializes in agricultural technology including agricultural production
                machinery, materials/ingredients to the bio-fertilizer industry, beneficial soil microbes, and
                automation including IoT and robotics.
            </p>
            <div class="read-more-content">
                <p>
                    She has co-authored a book on Beneficial Microbes for Agricultural Applications. Ms. Gitau holds a
                    Bachelor from the University of Kabianga and is working on her Master’s Degree at the University of
                    Nairobi.
                </p>
            </div>
            <p class="read-more-button">Read More</p>
        </div>

        <div class="csubcontainer">
            <img class="cardimg" src="/assets/images/ithagi.jpg" alt=""/>
            <h3>Charles Ithagi</h3>
            <p>
                Mr. Ithagi is a patent agent who specializes in business methods, software, and blockchain inventions.
                He has worked on patent applications in the fields of energy storage, chemical synthesis, and personal
                care products, as well as
            </p>
            <div class="read-more-content">
                <p>
                    in the pharmaceutical field relating to drugs, methods of treatment, stem cell compositions, and
                    gene therapy; and he has interacted directly with clients and inventors for scientific strategy
                    support. He thinks beyond the limits of technology to encompass leading-edge processes and
                    individual talents for a unique blend that benefits our professionals, our clients, and our
                    communities. Mr. Ithagi holds a Bachelor of Commerce from the University of Nairobi.
                </p>
            </div>
            <p class="read-more-button">Read More</p>
        </div>
        <div class="csubcontainer">
            <img class="cardimg" src="/assets/images/Mary.jpg" alt=""/>
            <h3>Mary Kimani</h3>
            <p>
                Mary is the managing director for PatentPC’s Africa office serving African clients on their US and Asia
                patent needs. She is highly experienced with agricultural technology including agricultural production
                machinery, materials/ingredients
            </p>
            <div class="read-more-content">
                <p>
                    to the bio-fertilizer industry, beneficial soil microbes, and automation including IoT and robotics.
                    She has co-authored a book on Beneficial Microbes for Agricultural Applications. She has experience
                    with import/export rules for Kenya. She counsels her African clients on complex IP-based legal
                    questions and how to best protect complicated devices, systems, and methods in numerous technical
                    fields globally. Her mission is to provide legal services and solutions that consistently exceed
                    client expectations, create healthy, sustainable work environments for our professionals, and
                    positively impact our communities. Ms. Kimani has a Bachelor of Agribusiness Management from Rongo
                    University.
                </p>
            </div>
            <p class="read-more-button">Read More</p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
//This will not do anything
